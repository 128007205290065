/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 // You can delete this file if you're not using it
require("prismjs/themes/prism.css")

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    const offset = -250; // Adjust this value to control the offset

    const scrollToElement = (element) => {
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: y, behavior: "smooth" });
    };

    const observer = new MutationObserver(() => {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        console.log("Element found by MutationObserver, scrolling with offset");
        scrollToElement(element);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Initial check in case the element is already there
    const element = document.getElementById(location.hash.substring(1));
    if (element) {
      console.log("Element found on initial load, scrolling with offset");
      scrollToElement(element);
      observer.disconnect();
    }
  }
};


